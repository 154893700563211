import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// use the require method provided by webpack
declare const require;
// we use the webpack raw-loader to return the content as a string
const translations = {
  // fr: require(`raw-loader!./locale/messages.fr.xlf`),
};

let options = (() => {
  if(window.localStorage) {
    let lang = window.localStorage.getItem('lang');
    if(lang !== null) {
      return [
        { provide: TRANSLATIONS, useValue: translations[lang] },
        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
      ]
    }
  }
  return [];
})();

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: options
}).catch(err => console.log(err));
