import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
// we use the webpack raw-loader to return the content as a string
var translations = {
// fr: require(`raw-loader!./locale/messages.fr.xlf`),
};
var ɵ0 = function () {
    if (window.localStorage) {
        var lang = window.localStorage.getItem('lang');
        if (lang !== null) {
            return [
                { provide: TRANSLATIONS, useValue: translations[lang] },
                { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
            ];
        }
    }
    return [];
};
var options = (ɵ0)();
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
    providers: options
}).catch(function (err) { return console.log(err); });
export { ɵ0 };
