import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Two
  {
    path: 'shutdown',
    loadChildren: './shutdown/shutdown.module#ShutdownModule',
  },
  {
    path: "**",
    redirectTo: '/shutdown'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: Boolean(history.pushState) === false,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
