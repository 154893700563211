import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { MatButtonModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ng-pipes';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpModule,
    MatButtonModule,
    RouterModule,
    NgPipesModule
  ],

  declarations: [
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    HttpModule,
    RouterModule,
    NgPipesModule
  ],
})
export class SharedModule { }
